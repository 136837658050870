import React from 'react'
import styled from "styled-components"
import { useHistory } from 'react-router-dom'

import { device } from '../device'

// import AboutUs1 from "../assets/home-aboutUs2.jpg"
import AboutUs2 from "../assets/home-aboutUs1.jpg"
import wheelBalancingImg from "../assets/wheelbalancing.jpg"
import wheelAlignmentImg from "../assets/wheelsts3.jpeg"
import headlampAdjustmentImg from "../assets/lampsts.jpg"
import diskbrakeLatheImg from "../assets/wheelsts2.jpg"


const Container = styled.section`
// background: papayawhip;
// width: 100%;
padding:0 100px;

// & div {
//     border: 1px solid blue;
// }

@media ${device.mobileL} {
    max-width: 425px;
    padding: 0 20px;
}

@media ${device.laptop} { 
    max-width: 1024px;
    padding: 0 20px;
}

@media ${device.desktop} {
    max-width: 100%;
}
`

const Header = styled.div`
height: 10%;
width: 100%;
& p {
    font-size: 49px;
    font-family: 'Bebas Neue';
    font-weight: bold;
    align-items: baseline;
    margin-top: 80px;
    margin-bottom: 0;
}
margin-bottom: 80px;

@media ${device.mobileL} {
    margin-bottom: 32px;
}

@media ${device.laptop} {
    margin-bottom: 32px;
}
`

const Body = styled.div`
height: auto;
width: 100%;
border-bottom: 2px solid #E9E9E9;
`

const ImageContainer = styled.div`
position: relative;
width: 50%;
padding: 0 50px;

@media ${device.mobileL} {
    width: 100%;
    display: flex;
    padding: 0px;
    justify-content: space-evenly;
}

@media (min-width: 426px) and (max-width: 768px) {
    width: 70%;
    display: flex;
}

@media (min-width: 769px) and (max-width: 1024px) {
    width: 70%;
    display: flex;
    padding: 0px;
}
`

const ParagrafContainer = styled.div`
width: 50%;
// padding-top: 20px;
& p {
    font-size: 16px;
    font-family: 'Poppins';
}

& h1 {
    font-size: 21px;
    margin-bottom: 35px;
    font-family: 'Poppins';
}

@media ${device.mobileL} {
    width: 100%;
}

@media (min-width: 426px) and (max-width: 1024px) {
    width: 30%;
}
`

const SubBody1 = styled.div`
display: flex;
height: 480px;
width: 100%;
margin-bottom: 120px;
justify-content: space-around;
& ${ImageContainer}{
    text-align: right;
}
& ${ParagrafContainer}{
    padding-left: 10px;
    text-align: left;
}

& img {
    padding-left: 20px;
}

@media ${device.mobileL} {
    display: block;
    margin-bottom: 40px;
    height: auto;
    & ${ImageContainer}{
        text-align: inherit;
    }

    & img {
        padding: 0 10px;
    }
}

@media ${device.laptop} {
    height: auto;
    
    & img {
        padding-left: 0px;
        padding: 0 10px;
    }
}
`

const SubBody2 = styled.div`
display: flex;
height: 480px;
width: 100%;
margin-bottom: 80px;
& ${ImageContainer}{
    text-align: left;
}
& ${ParagrafContainer}{
    padding-right: 10px;
    text-align: right;
}

& img {
    padding-right: 20px;
}
@media ${device.mobileL} {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    margin-bottom: 32px;
    & ${ImageContainer}{
        text-align: inherit;
    }

    & img {
        padding: 0 10px;
        padding-right: 0px;
    }
}

@media ${device.laptop} {
    height: auto;
    
    & img {
        padding-right: 0px;
        padding: 0 10px;
    }
}

`


const AboutUsImage1 = styled.img`
position: relative;
max-width: 300px;
width: 50%;
height: auto;
object-fit: cover;

@media ${device.mobileL} {
    width: 35%;
}

// @media ${device.laptop} {
//     width: 144px;
// }
`

const AboutUsImage2 = styled.img`
position: relative;
max-width: 220px;
width: 40%;
height: auto;
object-fit: cover;

@media ${device.mobileL} {
    width: 35%;
}

@media (min-width: 426px) and (max-width: 1024px) {
    width: 50%;
}
`

const ButtonDetail = styled.button`
width: 80px;
height: 40px;
font-size: 16px;
font-family: 'Poppins';
font-weight: bold;
background: #FFFFFF;
margin-top: 10px;
`

export default function services() {
    let history = useHistory()
    return (
        <>
        <Container>
            <Header><p>Services</p></Header>
            <Body>
                <SubBody1>
                    <ImageContainer>
                        <AboutUsImage2 src={AboutUs2}/>
                        <AboutUsImage1 src={wheelBalancingImg}/>
                    </ImageContainer>
                    <ParagrafContainer>
                        <h1>Wheel Alignment</h1>
                        <p>Steering wheel not centered when trying to drive in a straight line? Car pulls to one of the sides?</p>
                        <ButtonDetail onClick={() => {history.push('/services/wheelBalancing'), event.preventDefault()}}>Details</ButtonDetail>
                    </ParagrafContainer>
                </SubBody1>
                <SubBody2>
                    <ParagrafContainer>
                        <h1>Wheel Balancing</h1>
                        <p>Feeling like your steering wheel or car body during high speed is vibrating like riding a horse? Usually it is caused by imbalanced wheels</p>
                        <ButtonDetail onClick={() => {history.push('/services/wheelAlignment'), event.preventDefault()}}>Details</ButtonDetail>
                    </ParagrafContainer>
                    <ImageContainer>
                        <AboutUsImage1 src={wheelAlignmentImg}/>
                        <AboutUsImage2 src={AboutUs2}/>
                    </ImageContainer>
                </SubBody2>
                <SubBody1>
                    <ImageContainer>
                        <AboutUsImage2 src={AboutUs2}/>
                        <AboutUsImage1 src={diskbrakeLatheImg}/>
                    </ImageContainer>
                    <ParagrafContainer>
                        <h1>Disk Brake Lathe</h1>
                        <p>Vibrations during braking? Sounds funny when braking?</p>
                        <ButtonDetail onClick={() => {history.push('/services/diskbrakeLathe'), event.preventDefault()}}>Details</ButtonDetail>
                    </ParagrafContainer>
                </SubBody1>
                <SubBody2>
                    <ParagrafContainer>
                        <h1>Headlamp Adjustment</h1>
                        <p>Too high or too low, sometimes you just need it to be just right. Correct headlamp adjustment is really important to safety driving</p>
                        <ButtonDetail onClick={() => {history.push('/services/headlampAdjustment'), event.preventDefault()}}>Details</ButtonDetail>
                    </ParagrafContainer>
                    <ImageContainer>
                        <AboutUsImage1 src={headlampAdjustmentImg}/>
                        <AboutUsImage2 src={AboutUs2}/>
                    </ImageContainer>
                </SubBody2>
            </Body>
            </Container>
        </>
    )
}
