import React, { /* useState , */ useEffect } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { device } from '../device'

// assets import
// import Burger_Menu from "../assets/Burger_Menu.svg"
// import Start_Tire_Logo from "../assets/starttire_logo.png"
// import IntroImage from "../assets/Intro_Banner.jpg"
import starttireBanner from "../assets/starttire-banner-3.jpeg"
import AboutUs1 from "../assets/home-aboutUs2.jpg"
import AboutUs2 from "../assets/home-aboutUs1.jpg"
// import ServicesMediumImg from "../assets/services-medium.jpg"
import ServicesLargeImg from "../assets/services-large-2.jpg"
import ArrowButton from "../assets/arrow-button.png"
import WheelCheck1 from "../assets/wheelbalancing.jpg"
import WheelCheck2 from "../assets/wheelsts3.jpeg"
import WheelCheck3 from "../assets/wheelsts2.jpg"
import HeadlampAdjustment from "../assets/lampsts.jpg"
// import NewsImg from "../assets/news-image.jpg"

// import { setLanguage } from '../store/action'

import { connect } from 'react-redux'
// import { getLanguage } from "../store/action"

//styled component css
const Container = styled.section`
// background: papayawhip;
// width: 100%;
box-sizing: border-box;
padding:0 100px;

@media ${device.mobileL} {
    max-width: 425px;
    padding: 0 20px;
}

@media ${device.tablet} {
    max-width: 425px;
    padding: 0 30px;
}

@media ${device.laptop} { 
    max-width: 1024px;
    padding: 0 30px;
}

@media ${device.desktop} {
    max-width: 100%;
}
`

// const Navbar = styled.div`
// // display: flex;
// // border: 1px solid blue;
// width: 100%;
// height: 132px;
// position: sticky;
// top: 0;
// z-index: 1;
// background: #FFFFFF;
// border-bottom: 2px solid #E9E9E9;
// `

// const LogoContainer = styled.div`
// margin: 0;
// position: absolute;
// top: 50%;
// left: 48%;
// -ms-transform: translateY(-50%);
// transform: translateY(-50%);
// z-index: 2;
// transition: top 0.5s;
// `

// const StartTireLogo = styled.img`
// width: 70px;
// height: 45px;
// `

// const BurgerMenu = styled.img`
// width: 32px;
// height: 32px;
// `
// const BurgerBarContainer = styled.a`
// margin: 0;
// position: absolute;
// top: 50%;
// -ms-transform: translateY(-50%);
// transform: translateY(-50%);
// z-index: 2
// `

// const Header = styled.h1`
// color: red;
// font-size: 200px;
// text-align: center;
// `
const IntroBannerContainer = styled.div`
width: 33.3%;
height: 100%;
// border: 1px solid yellow;
position: relative;
z-index: -1;

@media ${device.mobileL} {
    width: 100%;
    height: auto;
}

@media ${device.tablet} {
    width: 100%;
    height: auto;
}
`

const ButtonDetail = styled.button`
width: 80px;
height: 40px;
font-size: 16px;
font-family: 'Bebas Neue';
font-weight: bold;
background: #FFFFFF;
margin-top: 10px;
border: 1px solid black;
`

const SubContainer = styled.div`
display: flex;
// flex-direction: column;
// border: 1px solid black;
width: 100%;
height: 480px;
// background: grey;
z-index: -1;
padding-top: 50px;

& ${IntroBannerContainer}.container-aboutus-wording {
    z-index: 1;
}

@media ${device.mobileL} {
    width: 100%;
    flex-direction: row;
    padding-top: 10px;
    display: block;
    height: auto;
}
`

const SubContainerService = styled.div`
// border: 1px solid black;
width: 100%;
height: 400px;
// background: grey;
z-index: -1;
margin-bottom: 100px;

@media ${device.mobileL} {
    width: 100%;
    flex-direction: row;
    display: block;
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}
`
const WordingContainer = styled.div`
position: absolute;
top: 50%;
transform: translateY(-50%);

@media ${device.mobileL} {
    position: inherit;
    transform: none;
    top: inherit;
}
`

const IntroHeader = styled.p`
font-size: 16px;
font-family: 'Bebas Neue';
font-weight: bold;
`

const IntroParagraf = styled.p`
width: 100%;
font-family: 'Poppins';
font-size: 12px;
line-height: 30px
`

const IntroBannerImage = styled.img`
position: absolute;
width: 80%;
height: 100%;
right: 0;
top: 50%;
transform: translateY(-50%);

@media ${device.mobileL} {
    width: 100%;
    height: auto;
    float: inherit;
    position: relative;
    right: inherit;
    top: inherit;
    transform: inherit;
}

@media ${device.laptop} {
    height: 70%;
}

@media ${device.tablet} {
    height: 50%;
}
`

const IntroTaglineContainer = styled.div`
position: absolute;
right: 0;
top: 45%;
transform: translateY(-55%);

@media ${device.mobileL} {
    right: inherit;
    top: inherit;
    position: inherit;
    transform: none;
    padding: 20px 0;
    // display: none;
}
`

const IntroTagLine1 = styled.p`
font-size: 65px;
font-family: 'Bebas Neue';
font-weight: bold;
margin: 0px;
text-align: right;

@media ${device.tablet} {
    font-size: 40px;
}
`
const IntroTagLine2 = styled.p`
font-size: 49px;
font-family: 'Poppins';
margin: 0px;
text-align: right;

@media ${device.tablet} {
    font-size: 24px;
}
`
const IntroTagLine3 = styled.p`
font-size: 16px;
font-family: 'Poppins';
margin: 0px;
text-align: right;

@media ${device.tablet} {
    font-size: 12px;
}
`

const AboutUsImage1 = styled.img`
position: absolute;
width: 70%;
height: 360px;
top: 50%;
transform: translateY(-50%);
left: 0;
object-fit: cover;

@media ${device.mobileL} {
    position: inherit;
    width: 100%;
    height: auto;
    top: inherit;
    transform: inherit;
    left: inherit;
}

@media ${device.laptop} {
    height: 70%;
}

@media ${device.tablet} {
    height: 50%;
}
`

const AboutUsImage2 = styled.img`
position: absolute;
width: 70%;
height: 360px;
top: 50%;
transform: translateY(-50%);
right: 0;
object-fit: cover;

@media ${device.mobileL} {
    position: inherit;
    width: 100%;
    height: auto;
    top: inherit;
    transform: inherit;
    right: inherit;
}

@media ${device.laptop} {
    
    height: 70%;
}

@media ${device.tablet} {
    height: 50%;
}
`
const AboutUsWordingContainer = styled.div`
position: absolute;
top: 50%;
transform: translateY(-50%);

& ${ButtonDetail}{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@media ${device.mobileL} {
    position: inherit;
    top: inherit;
    transform: none;
    padding: 30px 0;
}
`

const AboutUsHeader = styled.p`
text-align: center;
font-size: 49px;
font-family: 'Bebas Neue';
font-weight: bold;
margin: 0;
`

const AboutUsBody = styled.p`
text-align: center;
font-family: 'Poppins';
font-size: 16px;
`

const ServiceHeader = styled.div`
position: relative;
display: flex;
width: 100%;
height: 20%;
// border: 1px solid cyan;

@media ${device.mobileL} {
    margin-bottom: 16px;
}
`

const ServiceHalfContainer = styled.div`
height: 100%;
width: 50%;
// border: 1px solid yellow;

@media ${device.mobileL} {
    width: 100%;
    display: block;
    // height: auto;
}
`

const ServiceHeaderHalfContainer = styled.div`
height: 100%;
width: 50%;
// border: 1px solid yellow;
`

const ServiceHeaderWording = styled.p`
margin: 0;
font-family: 'Bebas Neue';
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 0;
font-size: 49px;
font-weight: bold;

@media ${device.mobileL} {
    position: inherit;
    top: inherit;
    transform: none;
    left: inherit;
}
`

const ServiceHeaderLink = styled.a`
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 0;
font-family: 'Poppins';
font-size: 16px;
font-weight: bold;
color: #B52025;

@media ${device.mobileL} {
    display: none;
}
`

const ServiceBody = styled.div`
display: flex;
height: 80%;

@media ${device.mobileL} {
    width: 100%;
    display: block;
    // height: auto;

    & ${ServiceHalfContainer}.description-half {
        margin-top: 20px;
    }
}
`

const CarrouselContainer = styled.div`
// display: flex;
width: 100%;
height: 100%;
// border: 1px solid magenta;
// overflow: hidden;
// & ul {
//     display: inline-flex;
//     height: 100%;
//     padding: 0;
// }

& div {
    display: none;
    position: relative;
    height: 100%;
}

// & li.active-service-display {
//     display: inline-flex;
// }

& div.active-service-display {
    display: flex;
    position: relative;
    
}

@media ${device.mobileL} {
    & li.active-service-display {
        padding: 0 10px;
    }
}
`

const ServiceImageMedium = styled.img`
width: 40%;
height: 80%;
position: absolute;
bottom: 0;
left: unset;
object-fit: cover;

@media ${device.mobileL} {
    width: 100px;
    height: auto;
    padding: inherit;
    position: inherit;
    bottom: inherit;
    left: inherit;
    padding-right: 10px;
    transform: unset;
    top: inherit;
}

@media (min-width: 426px) and (max-width: 768px) {
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
}

@media ${device.laptop} {
    width: 40%;
}
`

const ServiceImageLarge = styled.img`
width: 45%;
height: 100%;
position: absolute;
bottom: 0;
left: 45%;
object-fit: cover;

@media ${device.mobileL} {
    width: 100px;
    height: auto;
    padding: inherit;
    position: inherit;
    bottom: inherit;
    left: inherit;
    transform: unset;
    top: inherit;
}

@media (min-width: 426px) and (max-width: 768px) {
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
}

@media ${device.laptop} {
    width: 45%;
}
`

const ServiceDescriptionContainer = styled.div`
height: 70%;
margin: 0;
overflow: hidden;
& ul {
    list-style: none;
    display: contents;
    // width: 100%;
}

& li {
    max-width: 640px;
    display: none;
}

& li.active-service-display {
    display: block;
}

@media ${device.mobileL} {
    width: 100%;
    // height: auto;

    & ul {
        padding: 0;
    }

    & li {
        width: auto;
        height: auto;
    }
}

@media ${device.laptop} {
    // height: auto;
    & ul {
        padding: 0;
    }

    & li {
        width: auto;
        height: auto;
    }
}
`


const DescriptionHead = styled.p`
font-size: 21px;
font-family: 'Bebas Neue';
font-weight: bold;
margin: 0;
`

const DescriptionBody = styled.p`
font-size: 16px;
width: 80%;
font-family: 'Poppins';
`

const ServiceCarrouselContainer = styled.div`
display: flex;
position: relative;
height: 30%;
// border: 1px solid red;

@media ${device.mobileL} {
    margin-top: 30px;
}
`

const ServiceCarrouselTabContainer = styled.div`
display: flex;
position: absolute;
left: 10px;
bottom: 0;
align-items: baseline;
`

const ServiceCarrouselButtonContainer = styled.div`
display: flex;
position: absolute;
right: 0;
bottom: 0;
`

const CarrouselTracker = styled.div`
width: 10px;
height: 10px;
background-color: #E9E9E9;
margin: 2px;
&.active-service {
    background-color: #212121;
    width: 15px;
    height: 15px;
}
`
const CarrouselButtonLeft = styled.div`
display: flex;
width: 40px;
height: 40px;
background-color: #E9E9E9;
align-items: center;
justify-content: center;
`

const CarrouselButtonRight = styled.div`
display: flex;
width: 40px;
height: 40px;
background-color: #212121;
align-items: center;
justify-content: center;
transform: scaleX(-1);
`


function Home(props){
    let content = {
        English : {
            intro_header: "Wheel alignment problem-solver since 1972",
            Intro_paragraf: "With nearly 50 years of experience and keeping up with high quality work together with utilizing only high quality precision machines, we have kept drivers satisfied, comfortable and safe since 1972.",
            aboutus_header: "ABOUT US",
            aboutus_body: "Start Tire Service opened its first workshop in Pecenongan in 1972. Since then, the brand has always been highly specialized in Wheel Alignment and Wheel Balancing. Quite possibly one of the first of its kind in Indonesia at the time.",
            service_header: "SERVICES",
            service_link: "All Services",
            description_head_alignment: "Wheel Alignment",
            description_body_alignment: "Steering wheel not centered when trying to drive in a straight line? Car pulls to one of the sides?",
            description_head_balancing: "Wheel Balancing",
            description_body_balancing: "Feeling like your steering wheel or car body during high speed is vibrating like riding a horse? Usually it is caused by imbalanced wheels.",
            description_head_diskbrake: "Disk Brake Lathe",
            description_body_diskbrake: "Vibrations during braking? Sounds funny when braking?",
            description_head_headlamp: "Headlamp Adjustment",
            description_body_headlamp: "Too high or too low, sometimes you just need it to be just right. Correct headlamp adjustment is really important to safety driving",
            details_button: "Details",
            news_header: "News",
            news_description: ["Lorem Ipsum is simply simply dummY...", "Lorem Ipsum is simply simply dummY...", "Lorem Ipsum is simply simply dummY...", "Lorem Ipsum is simply simply dummY...", "Lorem Ipsum is simply simply dummY..."] 
        },
        Indonesia: {
            intro_header: "Spesialis SPOORING & BALANCING sejak 1972",
            Intro_paragraf: "Dengan hampir 50 tahun pengalaman di bidang ini, kami berkomitmen memberikan kualitas kerja yang tinggi dengan hanya menggunakan teknologi mesin presisi  yang berkualitas, Start Tire Service telah membuat konsumen puas, nyaman dan aman sejak tahun 1972.",
            aboutus_header: "TENTANG KAMI",
            aboutus_body: "Start Tire Service membuka cabang pertama kami di Pecenongan pada tahun 1972. Semenjak itu, brand kami selalu dikenal sebagai spesialis dalam bidang Spooring dan Balancing. Bisa dikatakan satu satunya dan yang pertama di Indonesia pada saat itu.",
            service_header: "JASA",
            service_link: "Semua Jasa",
            description_head_alignment: "SPOORING BAN",
            description_body_alignment: "Apakah anda merasa kemudi kendaraan mobil anda tidak stabil saat mencoba berjalan lurus ? Atau Mobil anda selalu menarik diri ke salah satu sisi tanpa anda gerakkan ?",
            description_head_balancing: "BALANCING",
            description_body_balancing: "Apakah anda pernah merasa setir dan body mobil anda tidak stabil saat berkemudi dengan kecepatan tinggi seperti mengendarai kuda ? Biasanya hal ini disebabkan oleh kondisi roda yang tidak stabil.",
            description_head_diskbrake: "BUBUT REM CAKRAM",
            description_body_diskbrake: "Pernahkah anda mengalami getaran pada kendaraan anda saat menginjak rem?  Pernah mendengar bunyi yang lucu atau bisa jadi menjengkelkan setiap anda menginjak rem?",
            description_head_headlamp: "PENYESUAIAN LAMPU DEPAN",
            description_body_headlamp: "Pernah mengalami  posisi lampu depan mobil terlalu tinggi atau terlalu rendah ?  Kamu membutuhkan penyesuaian.",
            details_button: "Detail",
            news_header: "Berita",
            news_description: ["Lorem Ipsum indonesia simply simply dummY...", "Lorem Ipsum indonesia simply simply dummY...", "Lorem Ipsum indonesia simply simply dummY...", "Lorem Ipsum indonesia simply simply dummY...", "Lorem Ipsum indonesia simply simply dummY..."]
        }
    }

    let {language} = props

    let history = useHistory()
    useEffect(() => {
        console.log('ini props log', props)
    }, [])

    const navigate = (params) => {
        event.preventDefault()
        history.push(params)
    }

    const nextService = (e) => {
        e.preventDefault()
        let currentService = document.getElementsByClassName("active-service")
        let displayedCurrentService = document.getElementsByClassName("active-service-display")
        let currentServiceId = currentService[0].id

        let nextServiceId = currentServiceId === "service-4" ? "service-1" : `service-${Number(currentServiceId.split("-")[1]) + 1}`
        let elementNext = document.getElementById(nextServiceId)
        let imageNext = document.getElementById(`image-${nextServiceId}`)
        let captionNext = document.getElementById(`caption-${nextServiceId}`)

        currentService[0].classList.remove("active-service")
        Array.from(displayedCurrentService).forEach(val => {
            val.classList.remove("active-service-display")
        })

        elementNext.classList.add("active-service")
        imageNext.classList.add("active-service-display")
        captionNext.classList.add("active-service-display")
    }

    const prevService = (e) => {
        e.preventDefault()
        let currentService = document.getElementsByClassName("active-service")
        let displayedCurrentService = document.getElementsByClassName("active-service-display")
        let currentServiceId = currentService[0].id

        let prevServiceId = currentServiceId === "service-1" ? "service-4" : `service-${Number(currentServiceId.split("-")[1]) - 1}`
        let elementPrev = document.getElementById(prevServiceId)
        let imagePrev = document.getElementById(`image-${prevServiceId}`)
        let captionPrev = document.getElementById(`caption-${prevServiceId}`)

        currentService[0].classList.remove("active-service")
        Array.from(displayedCurrentService).forEach(val => {
            val.classList.remove("active-service-display")
        })

        elementPrev.classList.add("active-service")
        imagePrev.classList.add("active-service-display")
        captionPrev.classList.add("active-service-display")
    }

    return (
        <div>
            <Container>
                <SubContainer className="intro">
                    
                    <IntroBannerContainer>
                        <WordingContainer>
                            <IntroHeader>{content[language].intro_header}</IntroHeader>
                            <IntroParagraf>{content[language].Intro_paragraf}</IntroParagraf>
                        </WordingContainer>
                    </IntroBannerContainer>
                    
                    <IntroBannerContainer>
                        <IntroTaglineContainer>
                            <IntroTagLine1>SOLUTION</IntroTagLine1>
                            <IntroTagLine2>fine-tuned</IntroTagLine2>
                            <IntroTagLine3>bring comfort speed</IntroTagLine3>
                        </IntroTaglineContainer>
                    </IntroBannerContainer>
                    
                    <IntroBannerContainer>
                        <IntroBannerImage src={starttireBanner}/>
                    </IntroBannerContainer>

                </SubContainer>
                <SubContainer className="home-aboutUs">
                    
                    <IntroBannerContainer>
                        <AboutUsImage1 src={AboutUs1}/>
                    </IntroBannerContainer>

                    <IntroBannerContainer className='container-aboutus-wording'>
                        <AboutUsWordingContainer>
                            <AboutUsHeader>{content[language].aboutus_header}</AboutUsHeader>
                            <AboutUsBody>{content[language].aboutus_body}</AboutUsBody>
                            <ButtonDetail onClick={() => navigate("aboutUs")}>{content[language].details_button}</ButtonDetail>
                        </AboutUsWordingContainer>
                    </IntroBannerContainer>
                    
                    <IntroBannerContainer>
                        <AboutUsImage2 src={AboutUs2}/>
                    </IntroBannerContainer>
                
                </SubContainer>
                <SubContainerService className="home-service">
                    <ServiceHeader>
                        <ServiceHeaderHalfContainer>
                            <ServiceHeaderWording>{content[language].service_header}</ServiceHeaderWording>
                        </ServiceHeaderHalfContainer>
                        <ServiceHeaderHalfContainer>
                            <ServiceHeaderLink href="" onClick={() => navigate("services")}>{content[language].service_link}</ServiceHeaderLink>
                        </ServiceHeaderHalfContainer>
                    </ServiceHeader>
                    <ServiceBody>
                        <ServiceHalfContainer className='image-half'>
                            <CarrouselContainer>
                                <div id="image-service-1" className="active-service-display">
                                    <ServiceImageMedium src={WheelCheck1} />
                                    <ServiceImageLarge src={ServicesLargeImg}/>
                                </div>

                                <div id="image-service-2">
                                    <ServiceImageMedium src={WheelCheck2}/>
                                    <ServiceImageLarge src={AboutUs2}/>
                                </div>

                                <div id="image-service-3">
                                    <ServiceImageMedium src={WheelCheck3}/>
                                    <ServiceImageLarge src={ServicesLargeImg}/>
                                </div>

                                <div id="image-service-4">
                                    <ServiceImageMedium src={HeadlampAdjustment}/>
                                    <ServiceImageLarge src={AboutUs1}/>
                                </div>
                            </CarrouselContainer>
                        </ServiceHalfContainer>
                        <ServiceHalfContainer className='description-half'>

                            <ServiceDescriptionContainer>
                                <ul>
                                    <li id="caption-service-1" className="active-service-display">
                                        <DescriptionHead>{content[language].description_head_alignment}</DescriptionHead>
                                        <DescriptionBody>{content[language].description_body_alignment}</DescriptionBody>
                                        <ButtonDetail onClick={() => navigate("services/wheelAlignment")}>{content[language].details_button}</ButtonDetail>
                                    </li>
                                    <li id="caption-service-2">
                                        <DescriptionHead>{content[language].description_head_balancing}</DescriptionHead>
                                        <DescriptionBody>{content[language].description_body_balancing}</DescriptionBody>
                                        <ButtonDetail onClick={() => navigate("services/wheelBalancing")}>{content[language].details_button}</ButtonDetail>
                                    </li>
                                    <li id="caption-service-3">
                                        <DescriptionHead>{content[language].description_head_diskbrake}</DescriptionHead>
                                        <DescriptionBody>{content[language].description_body_diskbrake}</DescriptionBody>
                                        <ButtonDetail onClick={() => navigate("services/diskbrakeLathe")}>{content[language].details_button}</ButtonDetail>
                                    </li>
                                    <li id="caption-service-4">
                                        <DescriptionHead>{content[language].description_head_headlamp}</DescriptionHead>
                                        <DescriptionBody>{content[language].description_body_headlamp}</DescriptionBody>
                                        <ButtonDetail onClick={() => navigate("services/headlampAdjustment")}>{content[language].details_button}</ButtonDetail>
                                    </li>
                                </ul>
                            </ServiceDescriptionContainer>

                            <ServiceCarrouselContainer>

                                <ServiceCarrouselTabContainer>
                                    <CarrouselTracker id="service-1" className="active-service"/>
                                    <CarrouselTracker id="service-2"/>
                                    <CarrouselTracker id="service-3"/>
                                    <CarrouselTracker id="service-4"/>
                                </ServiceCarrouselTabContainer>

                                <ServiceCarrouselButtonContainer>
                                    <CarrouselButtonLeft>
                                        <a href="" onClick={prevService}>
                                        <img src={ArrowButton}/>
                                        </a>
                                    </CarrouselButtonLeft>
                                    <CarrouselButtonRight>
                                        <a href="" onClick={nextService}>
                                        <img src={ArrowButton}/>
                                        </a>
                                    </CarrouselButtonRight>
                                </ServiceCarrouselButtonContainer>

                            </ServiceCarrouselContainer>

                        </ServiceHalfContainer>
                    </ServiceBody>
                </SubContainerService>
            </Container>
        </div>        
    )
}

Home.propTypes  = {
    language: PropTypes.string,
    // setLanguage: PropTypes.func
}

const mapStatetoProps = state => {
    console.log('ini store state', state)
    return {
        language: state.language,
    }
}

const mapDispatchtoProps = {
    // setLanguage
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Home)