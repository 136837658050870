import styled from "styled-components";
import { device } from "../device";

export const Container = styled.section`
  // background: papayawhip;
  // width: 100%;
  padding: 0 100px;

  // & div {
  //     border: 1px solid blue;
  // }
  @media ${device.mobileL} {
    max-width: 425px;
    padding: 0 20px;
  }

  @media (min-width: 426px) and (max-width: 1024px) {
    padding: 0 35px;
  }
`;

export const Header = styled.div`
  height: 10%;
  width: 100%;
  & p {
    font-size: 49px;
    font-family: "Bebas Neue";
    font-weight: bold;
    align-items: baseline;
    margin-top: 80px;
    margin-bottom: 0;
  }
  // margin-bottom: 20px;

  @media ${device.mobileL} {
    & p {
      margin-top: 30px;
    }
  }

  @media ${device.laptop} {
    & p {
      margin-top: 30px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  border-bottom: 2px solid #e9e9e9;
  padding-bottom: 50px;

  @media ${device.mobileL} {
    flex-direction: row;
    display: block;
  }
`;

export const ParagrafContainer = styled.div`
  width: 50%;
  padding-right: 10px;
  padding-top: 80px;
  & p {
    font-size: 16px;
    font-family: "Poppins";
  }

  & p.firstParagraph {
    text-indent: 0px;
  }

  @media ${device.mobileL} {
    width: 100%;
    padding-top: 20px;
  }

  @media ${device.laptop} {
    // width: 100%;
    padding-top: 0px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 50%;
  text-align: right;

  @media ${device.mobileL} {
    width: 100%;
    text-align: center;
    & img {
      max-width: 250px;
      padding-right: inherit;
    }
  }

  @media (min-width: 426px) and (max-width: 1024px) {
    text-align: center;
    margin-top: 20px;
    display: block;
    & img {
      max-width: 250px;
      // padding: 32px;
      padding-right: inherit;
      width: 100%;
    }
  }
`;

export const Image1 = styled.img`
  position: relative;
  max-width: 300px;
  width: 50%;
  height: auto;
  object-fit: cover;
`;

export const Image2 = styled.img`
  position: relative;
  max-width: 220px;
  width: 40%;
  height: auto;
  padding-right: 20px;
  object-fit: cover;

  @media ${device.laptop} {
    width: 50%;
  }
`;
