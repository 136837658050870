import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { device } from '../device'

import { connect } from 'react-redux'

const FooterContainer = styled.section`
display: flex;
// border: 1px solid red;
// width: 100%;
height: 300px;
margin-top: 100px;
margin-bottom: 24px;
padding: 0 100px;

@media ${device.mobileL}{
    display: block;
    margin-top: 20px;
    padding: 0 50px;
}
`

const SubFooter = styled.div`
width: 33.3%;
// border: 1px solid red;
height: 100%;
text-align: center;

@media ${device.mobileL}{
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    text-align: left;
}
`

const SubFooterHead = styled.div`
height: 20%;
width: 100%;
& p {
    font-size: 21px;
    font-weight: bold;
    font-family: 'Poppins';
}

@media ${device.mobileL} {
    height: auto;
    & p {
        font-size: 12px;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    & p {
        font-size: 18px;
    }
}
`

const SubFooterBody = styled.div`
height: 80%;
width: 100%;
& p {
    font-size: 16px;
    color: #B52025;
    font-family: 'Poppins';
}

& a {
    text-decoration: none;
}

@media ${device.mobileL} {
    height: auto;
    & p {
        font-size: 12px;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    & p {
        font-size: 18px;
    }
}
`

function Footer(props){

    let { language } = props

    const content = {
        English : {
            title_1: 'About Start Tire Service',
            title_2: 'Services',
            title_3: 'Social Media',
            mainlink_1: 'About Us',
            mainlink_2: 'Location',
            mainlink_3: 'Contact Us',
            mainlink_4: 'Special Offers',
            servicelink_1: 'Wheel Alignment',
            servicelink_2: 'Wheel Balancing',
            servicelink_3: 'Disk Brake Lathe',
            servicelink_4: 'Headlamp Adjustment'
        },
        Indonesia : {
            title_1: 'Tentang Start Tire Service',
            title_2: 'Jasa',
            title_3: 'Sosial Media',
            mainlink_1: 'Tentang Kami',
            mainlink_2: 'Lokasi Kami',
            mainlink_3: 'Hubungi Kami',
            mainlink_4: 'Penawaran Menarik',
            servicelink_1: 'Spooring',
            servicelink_2: 'Balancing',
            servicelink_3: 'Bubut Rem Cakram',
            servicelink_4: 'Penyesuaian Lampu Depan'
        }
    }

    let history = useHistory()
    useEffect(() => {

    }, [])
    return (
        <FooterContainer className="home-footer">
            <SubFooter>
                <SubFooterHead><p>{content[language].title_1}</p></SubFooterHead>
                <SubFooterBody>
                    <a href="" onClick={() => {history.push("/aboutUs"), event.preventDefault()}}><p>{content[language].mainlink_1}</p></a>
                    <a href="" onClick={() => {history.push("/location"), event.preventDefault()}}><p>{content[language].mainlink_2}</p></a>
                    <a href="" onClick={() => {history.push("/contactUs"), event.preventDefault()}}><p>{content[language].mainlink_3}</p></a>
                    {/* <a href="" onClick={() => {history.push("/specialOffers"), event.preventDefault()}}><p>{content[language].mainlink_4}</p></a> */}
                </SubFooterBody>
            </SubFooter>
            <SubFooter>
                <SubFooterHead><p>{content[language].title_2}</p></SubFooterHead>
                <SubFooterBody>
                    <a href="" onClick={() => {history.push("/services/wheelAlignment"), event.preventDefault()}}><p>{content[language].servicelink_1}</p></a>
                    <a href="" onClick={() => {history.push("/services/wheelBalancing"), event.preventDefault()}}><p>{content[language].servicelink_2}</p></a>
                    <a href="" onClick={() => {history.push("/services/diskbrakeLathe"), event.preventDefault()}}><p>{content[language].servicelink_3}</p></a>
                    <a href="" onClick={() => {history.push("/services/headlampAdjustment"), event.preventDefault()}}><p>{content[language].servicelink_4}</p></a>
                </SubFooterBody>
            </SubFooter>
            <SubFooter>
                <SubFooterHead><p>{content[language].title_3}</p></SubFooterHead>
                <SubFooterBody>
                    <a href="www.google.com"><p>Facebook</p></a>
                    <a href="www.google.com"><p>Twitter</p></a>
                    <a href="www.google.com"><p>Instagram</p></a>
                    <a href="www.google.com"><p>Youtube</p></a>
                </SubFooterBody>
            </SubFooter>
        </FooterContainer>
    )
}

Footer.propTypes  = {
    language: PropTypes.string
}

const mapStatetoProps = state => {
    console.log('ini store state', state)
    return {
        language: state.language,
    }
}

export default connect(mapStatetoProps)(Footer)