import React from "react"
import styled from "styled-components"
import PropTypes from 'prop-types'

import { device } from '../device'

import { connect } from 'react-redux'

import AboutUs1 from "../assets/home-aboutUs2.jpg"
import AboutUs2 from "../assets/home-aboutUs1.jpg"

const Container = styled.section`
// background: papayawhip;
// width: 100%;
padding:0 100px;

// & div {
//     border: 1px solid blue;
// }

@media ${device.mobileL} {
    max-width: 425px;
    padding: 0 50px;
}
`


const Header = styled.div`
height: 10%;
width: 100%;
& p {
    font-size: 49px;
    font-family: 'Bebas Neue';
    font-weight: bold;
    align-items: baseline;
    margin-top: 80px;
    margin-bottom: 0;
}
// margin-bottom: 20px;

@media ${device.mobileL} {
    & p {
        margin-top: 30px;
    }
}
`

const Body = styled.div`
display: flex;
height: auto;
width: 100%;
border-bottom: 2px solid #E9E9E9;
padding-bottom: 50px;

@media ${device.mobileL} {
    flex-direction: row;
    display: block;
}
`

const ParagrafContainer = styled.div`
width: 50%;
padding-right: 10px;
padding-top: 80px;
& p {
    font-size: 16px;
    font-family: 'Poppins';
}

& p.firstParagraph {
    // text-indent: 10px;
}

@media ${device.mobileL} {
    width: 100%;
    padding-top: 20px;
}

@media ${device.laptop} {
    // width: 100%;
    padding-top: 0px;
}
`

const ImageContainer = styled.div`
position: relative;
width: 50%;
text-align: right;

@media ${device.mobileL} {
    width: 100%;
    text-align: center;
    & img {
        max-width: 250px;
        padding-right: inherit;
    }
}

@media ${device.laptop} {
    text-align: center;
    & img {
        max-width: 250px;
        padding-right: inherit;
    }
}
`

const AboutUsImage1 = styled.img`
position: relative;
max-width: 300px;
height: auto;
`

const AboutUsImage2 = styled.img`
position: relative;
max-width: 220px;
height: auto;
padding-right: 20px;
`

function AboutUs(props) {

    let { language } = props

    let content = {
        English: {
            title: 'About Us',
            first_paragraph: 'opened its first workshop in Pecenongan in 1972. Since then, the brand has always been highly specialized in Wheel Alignment and Wheel Balancing. Quite possibly one of the first of its kind in Indonesia at the time.',
            second_paragraph: 'Founded by Albert Hardoyo at the age of 23, Start Tire Service started as a small family-owned car workshop in Pecenongan Street, Jakarta. The building that still exists today also served as the family home at the time. Despite being one of the Polio survivors from the age of 9, he worked his way from doing the wheel alignment of every car by himself until he got his first couple of employees, and finally opening new chains.',
            third_paragraph: 'Did you know that Start Tire Service brought the trend of filling car wheels with Nitrogen gas in Indonesia? From researching the benefits of Nitrogen gas and of its application at the time in airplane wheels, Start Tire Service tried applying the same principle to car wheels with better than expected results. Not only it is better for the tire’s rubber, but also provide more comfort to passengers. Start Tire Service has been using pure factory-produced 99.5% Nitrogen gas since 2003.',
            fourth_paragraph: 'Disc Brake Lathe was also a new thing back then in Indonesia. Before people know about the term Disc Brake Lathe, complaints about high pitch noise during braking were usually solved by either sandpapering the brake pads or exchanging the brake discs. Since the popularization of the service, we have saved countless customers from buying new disc brakes before the disc’s lifetime.',
            fifth_paragraph: 'Fast forward to today, the workshops are still family-owned, with 50 years of experience specializing in Wheel Alignment, Wheel Balancing, Disc Brake Lathe, and Headlamp Adjustment. Start Tire Service is proud to continue the high quality work, while utilizing top of the line precision machines.'
        },
        Indonesia: {
            title: 'Tentang Kami',
            first_paragraph: 'membuka cabang pertama kami di Pecenongan pada tahun 1972. Semenjak itu, brand kami selalu dikenal sebagai spesialis dalam bidang Spooring dan Balancing. Bisa dikatakan satu satunya dan yang pertama di Indonesia pada saat itu.',
            second_paragraph: 'Start Tire Service didirkan oleh Albert Handoyo pada usia 23 tahun. Bengkel ini merupakan perusahaan kecil keluarga yang didirikan di Jalan Pecenongan, Jakarta. Sekalipun menjadi pejuang Polio dari usia 9 tahun, beliau bekerja mulai dari tangannya sendiri hingga memiliki karyawan, dan berkembang membuka cabang-cabang baru. Bangunan bengkel yang dibangun lima dekade lalu ini, juga tetap melayani hingga hari ini.',
            third_paragraph: 'Start Tire Service merupakan bengkel bertama yang membawa trend gas nitrogen ke Indonesia.  Mulai dari melakukan riset dari manfaat gas nitrogen yang juga diisikan kepada ban pesawat,Start Tire Service mencoba melakukan prinsip yang sama kepada kendaraan mobil dan hasilnya sangat baik bahkan jauh melebihi ekspektasi kami. Tidak hanya lebih baik untuk keawetan karet ban, hal ini juga untuk memberikan kenyamanan berkendara bagi pengemudi dan penumpang kendaraan. Start Tire Service  menggunakan gas nitrogen dari pabrik dengan kandungan 99,5% nitrogen gas sejak 2003.',
            fourth_paragraph: 'Perawatan Bubut Rem juga merupakan hal baru di Indonesia. Sebelum masyarakat mengenal istilah bubut rem, keluhan tentang suara yang berisik pada saat menginjak rem biasanya diselesaikan dengan mengampelas bantalan rem, atau membeli rem baru. Sejak mempopulerkan layanan ini, kami membantu ribuan pelanggan berhemat dari membeli rem cakram yang baru sebelum masa pakai rem yang habis.',
            fifth_paragraph: 'Hingga hari ini, bengkel kami tetap menjadi perusahaan keluarga, dengan 50 tahun pengalaman sebagai spesialis di Spooring & Balancing, Bubut Rem, dan Penyesuaian Lampu depan Mobil. Terimakasih atas kepercayaan yang sudah diberikan kepada kami lebih dari 50 tahun. Izinkan Start Tire Service untuk menjadi jawaban bagi masalah kendaraan anda. Kami bangga untuk terus melanjutkan kerja keras kami memberikan pelayanan tinggi dengan menggunakan mesin presisi terbaik untuk memberikan kepuasan bagi konsumen.'
        }
    }

    return (
        <>
            <Container>
                <Header>
                    <p>{content[language].title}</p>
                    
                </Header>
                <Body>
                    <ParagrafContainer>
                    <p className="firstParagraph">
                        <b>Start Tire Service</b> {content[language].first_paragraph}
                    </p>
                    <p>
                        {content[language].second_paragraph}
                    </p>
                    <p>
                        {content[language].third_paragraph}
                    </p>
                    <p>
                        {content[language].fourth_paragraph}
                    </p>
                    <p>
                        {content[language].fifth_paragraph}
                    </p>
                    </ParagrafContainer>
                    <ImageContainer>
                        <AboutUsImage2 src={AboutUs2}/>
                        <AboutUsImage1 src={AboutUs1}/>
                    </ImageContainer>
                </Body>
            </Container>
        </>
    )
}

AboutUs.propTypes  = {
    language: PropTypes.string
}

const mapStatetoProps = state => {
    console.log('ini store state', state)
    return {
        language: state.language,
    }
}

export default connect(mapStatetoProps)(AboutUs)