import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { device } from '../device'

import { connect } from 'react-redux'

import { setLanguage } from '../store/action'

import ArrowMenu from "../assets/right-arrow-angle.svg"
import CancelMenu from "../assets/cancel.svg"

const Wrapper = styled.div`
height: 100%;
// padding: 0 100px;
width: 100%;
background-color: #FFFFFF;
z-index: 2;
position: fixed;
left: -150%;
top: 0;
transition: 0.5s;
& #closeButton {
    width: 25px;
    height: 25px;
    position: relative;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
}

@media ${device.mobileL} {
    max-width: 425px;
    // padding: 0 20px;
    // height: auto;
}

@media ${device.laptop} {
    padding: 0px;
}
`


const Header = styled.div`
height: 15%;
width: 100%;
top: 0;
// border-bottom: 2px solid #E9E9E9;
margin-bottom: 10px;
display: flex;
position: relative;

@media ${device.mobileL} {
    height: 10%;
    margin-bottom: 10px;
}
`

const LanguageSelection = styled.div`
position: absolute;
right: 150px;
top: 48%;
transform: translateY(-50%);

& #lang {
    font-family: Poppins;
    font-size: 16px;
    height: 32px;
}

@media ${device.mobileL} {
    right: 50px;
}
`

const CancelMenuImage = styled.img`
position: relative;
max-width: 25px;
max-height: 25px;
top: 50%;
left: 10px;
transform: translateY(-50%);

@media ${device.mobileL} {
    max-width: 15px;
    max-height: 15px;
}
`

const Body = styled.div`
display: flex;
width: 100%;
height: 85%;
& ul {
    list-style: none;
    padding-left: 5px;
    margin: 0;
}
& li {
    padding-bottom: 35px;
    font-size: 21px;
    display: flex;
    font-family: 'Poppins';
}

& a {
    text-decoration: none;
    color: black;
}

@media ${device.mobileL} {
    & li {
        padding-bottom: 25px;
        font-size: 16px;
        display: flex;
        font-family: 'Poppins';
    }   
}
`
const SideMenu = styled.div`
width: 30%;
height: 100%;
// border-left: 2px solid #E9E9E9;
padding-left: 20px;
`

const MainMenu = styled.div`
width: 30%;
height: 100%;

@media ${device.mobileL} {
    width: 50%;
}
`


const ServiceTab = styled.a`

`
const SubMenu = styled.div`
position: relative;
width: 30%;
top: 7%;
height: 300px;
transition: 0.5s left;

@media ${device.mobileL} {
    left: 15%;
}

@media ${device.laptop} {
    left: 15%;
}
`

const SubBodyMenu = styled.div`
display: flex;
width: 70%;
height: 100%;
overflow-y: auto;
padding: 0 3%;

@media ${device.mobileL} {
    height: inherit;
    width: 100%;
    padding: 0 20px;
}

@media ${device.laptop} {
    padding: 0 20px;
}
`



// const ArrowMenuContainer = styled.div`
// display: flex;
// width: 20px;
// height: 20px;
// align-items: center;
// justify-content: center;
// `

const ArrowMenuImage = styled.img`
position: relative;
width: 15px;
height: 15px;
top: 9px;
left: 5px;

@media ${device.mobileL} {
    width: 10px;
    height: 10px;
    top: 7px;
    left: 5px;
}
`

function closeNav(e) {
    e.preventDefault()
    document.getElementById("myNav").style.left = "-150%";
}

function Menu(props) {

    let {language, setLanguage} = props

    let content = {
        English: {
            home: "Home",
            about_us: "About Us",
            services: "Services",
            contact_us: "Contact Us",
            special_offers: "Special Offers",
            location: "Our Locations",
            wheel_alignment: "Wheel Alignment",
            wheel_balancing: "Wheel Balancing",
            diskbrake_lathe: "Disk Brake Lathe",
            headlamp_adjustment: "Headlamp Adjustment"
        },
        Indonesia: {
            home: "Halaman Utama",
            about_us: "Tentang Kami",
            services: "Jasa",
            contact_us: "Hubungi Kami",
            special_offers: "Penawaran Menarik",
            location: "Lokasi Kami",
            wheel_alignment: "Spooring Ban",
            wheel_balancing: "Balancing",
            diskbrake_lathe: "Bubut Rem Cakram",
            headlamp_adjustment: "Penyesuaian Lampu Depan"
        }
    }

    let history = useHistory()
    const [serviceStatus, setService] = useState(false)

    useEffect(() => {
        console.log('ini props di menu', props)
    }, [])

    const toggleService = () => {
        event.preventDefault()
        console.log('test')
        setService(!serviceStatus)
    }

    const navigate = (params) => {
        console.log('ini params', params)
        closeNav(event)
        if(serviceStatus) toggleService()
        history.replace(params)
        // history.push(params)
    }

    const changeLanguage = (val) => {
        console.log('val change', val)
        setLanguage(val)
    }

    return (
        <>
            <Wrapper id="myNav">
                <Header>
                    <div id="closeButton">
                        <a onClick={closeNav} href="">
                        <CancelMenuImage src={CancelMenu}/>
                        </a>
                    </div>
                    <LanguageSelection>
                        <select onChange={(e) => changeLanguage(e.target.value)} value={language} id="lang" name="language">
                            <option value="English">English</option>
                            <option value="Indonesia">Indonesia</option>
                        </select>
                    </LanguageSelection>
                </Header>
                <Body>
                    <SubBodyMenu>
                        <MainMenu>
                            <ul>
                                <li> <a href="" onClick={() => navigate("/")}>{content[language].home}</a> </li>
                                <li> <a href="" onClick={() =>  navigate("/aboutUs")}>{content[language].about_us}</a> </li>
                                <ServiceTab href="" onClick={toggleService}>
                                <li className="service-tab">{content[language].services} <ArrowMenuImage src={ArrowMenu}/></li>
                                </ServiceTab>
                                <li> <a href="" onClick={() =>  navigate("/contactUs")}>{content[language].contact_us}</a> </li>
                                <li> <a href="" onClick={() =>  navigate("/specialOffers")}>{content[language].special_offers}</a> </li>
                                <li> <a href="" onClick={() =>  navigate("/location")}>{content[language].location}</a> </li>
                            </ul>
                        </MainMenu>
                        {serviceStatus && 
                        <SubMenu className="ini-sub-menu">
                            <ul>
                                <li> <a href="" onClick={() =>  navigate("/services/wheelAlignment")}>{content[language].wheel_alignment}</a> </li>
                                <li> <a href="" onClick={() =>  navigate("/services/wheelBalancing")}>{content[language].wheel_balancing}</a> </li>
                                <li> <a href="" onClick={() =>  navigate("/services/diskbrakeLathe")}>{content[language].diskbrake_lathe}</a> </li>
                                <li> <a href="" onClick={() =>  navigate("/services/headlampAdjustment")}>{content[language].headlamp_adjustment}</a> </li>
                            </ul>
                        </SubMenu>}
                        
                    </SubBodyMenu>
                    <SideMenu>
                        {/* <ul>
                            <li>Reservation for Service</li>
                            <li>My Profile</li>
                            <li>Rewards</li>
                            <li>History Services</li>
                            <li>Log Out</li>
                        </ul> */}
                    </SideMenu>
                </Body>
            </Wrapper>
        </>
    )
}

Menu.propTypes  = {
    language: PropTypes.string,
    setLanguage: PropTypes.func
}

const mapStatetoProps = state => {
    console.log('ini store state', state)
    return {
        language: state.language,
    }
}

const mapDispatchtoProps = {
    setLanguage
}


export default connect(mapStatetoProps, mapDispatchtoProps)(Menu)