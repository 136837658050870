import axios from "axios";

export const UseApi = (method, url, params) => {
  console.log("masuk axios", method, url, params);
  return axios({
    method,
    url,
    data: { ...params },
  })
    .then(({ data }) => {
      console.log("cek data", data);
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
