import React, { useEffect } from 'react'
import styled from 'styled-components'
// import { connect } from 'react-redux'

import { device } from '../device'

import Burger_Menu from "../assets/Burger_Menu.png"
import Start_Tire_Logo from "../assets/starttire_logo.png"


import Menu from "../pages/menu" 

const Navbar__container = styled.div`
// display: flex;
// border: 1px solid blue;
width: 100%;
height: 100px;
position: sticky;
top: 0;
z-index: 1;
background: rgba(255, 255, 255, 0.8);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);

@media ${device.mobileL} {
    // left: 80%;
    height: 60px;
}
`

const LogoContainer = styled.div`
margin: 0;
position: absolute;
top: 50%;
left: 48%;
-ms-transform: translateY(-50%);
transform: translateY(-50%);
z-index: 2;
transition: top 0.5s;

@media ${device.mobileL} {
    // left: 80%;
    left: 45%;
}
`

const StartTireLogo = styled.img`
width: 90px;
height: 55px;

@media ${device.mobileL} {
    width: 50px;
    height: 30px;
}
`

const BurgerMenu = styled.img`
width: 32px;
height: 32px;

@media ${device.mobileL} {
    width: 20px;
    height: 20px;
}
`
const BurgerBarContainer = styled.a`
margin: 0;
position: absolute;
top: 50%;
left: 3%;
-ms-transform: translateY(-50%);
transform: translateY(-50%);
z-index: 2
`

const BottomLine = styled.div`
width: 100%;
border-bottom: 2px solid #E9E9E9;
position: absolute;
top: 100%;
// transition: top 0.5s;
`


function Navbar() {
    useEffect(() => {

    }, [])
    return (
        <>
        <Navbar__container>
            <LogoContainer id="logo-container">
                <StartTireLogo src={Start_Tire_Logo}/>
            </LogoContainer>
            <BurgerBarContainer href="#" onClick={openNav}>
                <BurgerMenu src={Burger_Menu}/>
            </BurgerBarContainer>
            <BottomLine id="bottom-line"></BottomLine>
        </Navbar__container>
        <Menu></Menu>
        </>
    )
}

function openNav(e) {
    e.preventDefault()
    document.getElementById("myNav").style.left = "0";
}

export default Navbar