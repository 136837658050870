import React, {useState, useEffect} from 'react'
import styled from "styled-components"
import Spinner from "react-spinkit"

import PropTypes from 'prop-types'

import { device } from '../device'

import { connect } from 'react-redux'

// import axios from 'axios'

// import MapImg from "../assets/map.png"
// import ReviewStarImg from "../assets/review-star.png"
import upArrow from "../assets/up-arrow.svg"
import downArrow from "../assets/down-arrow.svg"

const Container = styled.section`
// background: papayawhip;
// width: 100%;
padding:0 100px;

@media ${device.mobileL} {
    max-width: 425px;
    padding: 0 50px;
}

// & div {
//     border: 1px solid blue;
// }

& a, a:active, a:focus {
    outline: none;
}
`

const Header = styled.div`
height: 10%;
width: 100%;
margin-bottom: 80px;
& h1 {
    font-family: 'Bebas Neue';
    font-size: 49px;
    font-weight: bold;
    align-items: baseline;
    margin-top: 80px;
    margin-bottom: 0;
}
& p {
    font-size: 16px;
    font-family: 'Poppins';
}

@media ${device.mobileL} {
    margin-bottom: 30px;

    & h1 {
        margin-top: 30px;
        font-size: 28px;
    }
}
`

const Body = styled.div`
display: flex;
height: auto;
width: 100%;
padding-bottom: 80px;
border-bottom: 2px solid #E9E9E9;

@media ${device.mobileL} {
    display: block;
    flex-direction: row;
    padding-bottom: 30px;
}
`

const AddressContainer = styled.div`
width: 40%;
height: 500px;
overflow: scroll;
margin-right: 10%;
-ms-overflow-style: none;
scrollbar-width: none;
&::-webkit-scrollbar {
    display: none;
  }

& a {
    text-decoration: none;
    color: black;
}

@media ${device.mobileL} {
    width: 100%;
    overflow: none;
}
`

const MapContainer = styled.div`
width: 50%;
float: right;
height: auto;

& .text-center {
    text-align: center;
}

@media ${device.mobileL} {
    width: 100%;
    float: inherit;
    height: 400px;
}
`

const DetailContainer = styled.div`
    transition: 0.5s bottom;
`

const Address = styled.div`
position: relative;
min-height: 70px;
width: 100%;
border-bottom: 2px solid #E9E9E9;
padding-bottom: 20px;

& p.destination-name {
    display: inline-flex;
    font-weight: bold;
    font-family: 'Poppins';
    width: 100%;
}

& span.destination-text {
    width: 90%;
}

& p {
    font-size: 16px;
    font-family: 'Poppins';
}

& img {
    position: absolute;
    right: 0;
    height: 15px;
    width: 15px;
    margin-left: 10px;
}

// & .downArrow {
//     display: block;
// }

// & .upArrow {
//     display: none;
// }

// ${DetailContainer} {
//     display: none;
// }

${({active}) => active && `
    & .downArrow {
        display: none;
    }

    & .upArrow {
        display: block;
    }

    ${DetailContainer} {
        display: block;
    }
`}

`

// const MapImage = styled.img`
// max-height: auto;
// max-width: 100%;
// `

// const ReviewContainer = styled.div`
// display: flex;
// `


const DirectionButton = styled.button`
width: 100px;
height: 40px;
font-family: 'Bebas Neue';
font-size: 16px;
font-weight: bold;
background: #FFFFFF;
margin-top: 10px;
border: 1px solid black;
text-decoration: none;
`

function Location(props) {

    let {language} = props
    
    

    let content = {
        English: {
            header: 'Spot Our Location',
            sub_header: 'You can visit any of these locations and get your wheel alignment fixed in no time',
            directions: 'Directions'
        },
        Indonesia: {
            header: 'Temukan Kami',
            sub_header: 'Anda dapat mengunjungi lokasi tersebut dan menikmati Jasa kami dengan segera. ',
            directions: 'Arahkan'
        }
    }

    useEffect(() => {
        console.log('cek', active)
        console.log('cek2', isLoading)
        location

    }, [])

    const [active, setActive] = useState("Pecenongan")
    const [isLoading, setLoading] = useState(true)

    const toggleActiveTab = (params, id) => {
        console.log('ini active before set', active)
        console.log('trigger', params, id)
        setActive(id)
        if(active !== id){
            setLoading(true)
        }
        console.log('ini active after set', active)
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
      

    return (
        <>
            <Container>
                <Header>
                    <h1>{content[language].header}</h1>
                    <p>{content[language].sub_header}</p>
                </Header>
                <Body>

                    <AddressContainer>
                                <Address id="Pecenongan">
                                    <a href="" onClick={() => {toggleActiveTab("q=Start+Tire,Jakarta+Pusat", "Pecenongan"), event.preventDefault()}}>
                                    <p className="destination-name"><span className='destination-text'>Start Tire Service Pecenongan</span> { active === "Pecenongan" ? <img className="upArrow" src={upArrow} alt="up-arrow"/> : <img className="downArrow" src={downArrow} alt="down-arrow"/> } </p>
                                    {active === "Pecenongan" && 
                                    <DetailContainer>
                                        <p className="destination-address">Pecenongan St No.25, Kebon Kelapa, Gambir, Central Jakarta City, Jakarta 10120. <br/>Phone : (021) 3440680</p>
                                    </DetailContainer>
                                    }
                                    </a>
                                    {active === "Pecenongan" &&
                                    <DirectionButton onClick={() => {openInNewTab("https://www.google.co.id/maps/place/Start+Tire/@-6.165033,106.8242559,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69f5da67468251:0x1e73d1ef0b01c8c4!8m2!3d-6.165033!4d106.8264446"), event.preventDefault()}}>{content[language].directions}</DirectionButton>
                                    }
                                    
                                </Address>
                                <Address id="muaraKarang">
                                    <a href="" onClick={() => {toggleActiveTab("q=Start+Tire,Muara+Karang,Jakarta+Utara", "muaraKarang"), event.preventDefault()}}>
                                    <p className="destination-name"><span className='destination-text'>Start Tire Service Muara Karang</span> { active === "muaraKarang" ? <img className="upArrow" src={upArrow} alt="up-arrow"/> : <img className="downArrow" src={downArrow} alt="down-arrow"/> }</p>
                                    {active === "muaraKarang" && 
                                    <DetailContainer >
                                        <p className="destination-address">Jl. Muara Karang Raya No.243, Pluit, Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14450. <br/>Phone : (021) 6697420</p>
                                    </DetailContainer>
                                    }
                                    </a>
                                    {active === "muaraKarang" &&
                                        <DirectionButton onClick={() => {openInNewTab("https://www.google.co.id/maps/place/Start+Tire+Service/@-6.116232,106.7744913,17z/data=!3m1!4b1!4m5!3m4!1s0x2e6a1da202f76539:0x4c0c88f397dd9632!8m2!3d-6.116232!4d106.77668"), event.preventDefault()}}>{content[language].directions}</DirectionButton>
                                    }
                                </Address>
                                <Address id="BSD">
                                    <a href="" onClick={() =>  {toggleActiveTab("q=Start+Tire, Bumi+Serpong+Damai", "BSD"), event.preventDefault()}}>
                                    <p className="destination-name"><span className='destinattion-text'>Start Tire Service BSD</span> { active === "BSD" ? <img className="upArrow" src={upArrow} alt="up-arrow"/> : <img className="downArrow" src={downArrow} alt="down-arrow"/> }</p>
                                    {active === "BSD" && 
                                    <DetailContainer>
                                        <p className="destination-address">Bumi Serpong Damai Ruko Blok L No. 21-22, Lengkong Wetan, Serpong Sub-District, South Tangerang City, Banten 15311. <br/>Phone : (021) 5371012</p>
                                    </DetailContainer>
                                    }
                                    </a>
                                    {active === "BSD" &&
                                        <DirectionButton onClick={() => {openInNewTab("https://www.google.co.id/maps/place/Start+Tire+Service/@-6.1650284,106.756405,12z/data=!4m8!1m2!2m1!1sstart+tire+service+bsd!3m4!1s0x2e69fb17085e9c81:0xb8c22b15009c23de!8m2!3d-6.281216!4d106.662397"), event.preventDefault()}}>{content[language].directions}</DirectionButton>
                                    }
                                </Address>
                    </AddressContainer>
                    { active === "Pecenongan" &&

                    <MapContainer>
                        {/* <MapImage src={MapImg}/> */}
                        {isLoading ? 
                        <Spinner
                        className="loading text-center"
                        name="three-bounce"
                        color="black"
                        fadeIn="none"
                        /> : null
                        }
                        <iframe
                        className="iframe"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        onLoad={() => setLoading(false)}
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA99lS3obJx5eA63OKGg7FPhuxKQSU3FMI&q=Start+Tire,Pecenongan,Jakarta+Pusat" allowFullScreen>
                        </iframe>
                    </MapContainer>

                    }

                    { active === "muaraKarang" && 

                    <MapContainer>
                        {/* <MapImage src={MapImg}/> */}
                        {isLoading ? 
                        <Spinner
                        className="loading text-center"
                        name="three-bounce"
                        color="black"
                        fadeIn="none"
                        /> : null
                        }
                        <iframe
                        className="iframe"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        onLoad={() => setLoading(false)}
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA99lS3obJx5eA63OKGg7FPhuxKQSU3FMI&q=Start+Tire,Muara+Karang" allowFullScreen>
                        </iframe>
                    </MapContainer>

                    }

                    {   active === "BSD" && 

                    <MapContainer>
                        {/* <MapImage src={MapImg}/> */}
                        {isLoading ? 
                        <Spinner
                        className="loading text-center"
                        name="three-bounce"
                        color="black"
                        fadeIn="none"
                        /> : null
                        }
                        <iframe
                        className="iframe"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        onLoad={() => setLoading(false)}
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA99lS3obJx5eA63OKGg7FPhuxKQSU3FMI&q=Start+Tire,Bumi+Serpong+Damai" allowFullScreen>
                        </iframe>
                    </MapContainer>

                    }

                </Body>
            </Container>
        </>
    )
}

Location.propTypes  = {
    language: PropTypes.string
}

const mapStatetoProps = state => {
    console.log('ini store state', state)
    return {
        language: state.language,
    }
}

export default connect(mapStatetoProps)(Location)