import React, { useEffect } from "react";
// import logo from "./logo.svg";
import "./App.css";

import Home from "./pages/home";
// import Menu from "./pages/menu";
import AboutUs from "./pages/aboutUs";
import Services from "./pages/services";

import WheelBalancing from "./components/wheelBalancing";
import WheelAlignment from "./components/wheelAlignment";
import DiskbrakeLathe from "./components/diskbrakeLathe";
import HeadlampAdjustment from "./components/headlampAdjustment";
import ContactUs from "./pages/contactUs";
import Location from "./pages/location";
import SpecialOffers from "./pages/specialOffers";
import News from "./pages/news";
import SpecialOffersDetail from "./components/specialOfferDetail";

import Navbar from "./components/navbar";
import Footer from "./components/footer";
import ScrollToTop from "./scrollToTop";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import store from "./store";
// import aboutUs from "./pages/aboutUs";
// import specialOffers from "./pages/specialOffers";
import { Provider /*connect*/ } from "react-redux";

function App() {
  useEffect(() => {}, []);
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Navbar></Navbar>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/aboutUs" component={AboutUs} />
          <Route
            path="/services"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Services} exact />
                <Route
                  path={`${url}/wheelBalancing`}
                  component={WheelBalancing}
                />
                <Route
                  path={`${url}/wheelAlignment`}
                  component={WheelAlignment}
                />
                <Route
                  path={`${url}/diskbrakeLathe`}
                  component={DiskbrakeLathe}
                />
                <Route
                  path={`${url}/headlampAdjustment`}
                  component={HeadlampAdjustment}
                />
              </>
            )}
          />
          <Route path="/contactUs" component={ContactUs} />
          <Route path="/location" component={Location} />
          <Route path="/specialOffers" component={SpecialOffers} />
          <Route path="/news" component={News} />
          <Route path="/specialOfferDetail" component={SpecialOffersDetail} />
        </Switch>
        <Footer></Footer>
      </Router>
    </Provider>
    // <Menu></Menu>
    // <Home></Home>
    // <AboutUs></AboutUs>
    // <Services></Services>
    // <Location></Location>
    // <SpecialOffers></SpecialOffers>
    // <ContactUs></ContactUs>
    // <ServiceDetail></ServiceDetail>
  );
}

export default App;
