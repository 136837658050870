export function setLanguage(data) {
    return (dispatch) => {
        dispatch({
            type: "SET_LANGUAGE",
            data
        })
    }
}

export function getLanguage() {
    return (dispatch) => {
        dispatch({
            type: "GET_LANGUAGE"
        })
    }
}

export function setPromotionModal(data) {
    return (dispatch) => {
        dispatch({
            type: "SET_ACITVE_PROMOTION_MODAL",
            data
        })
    }
}

export function resetPromotionModal() {
    return (dispatch) => {
        dispatch({
            type: "RESET_ACTIVE_PROMOTION_MODAL"
        })
    }
}

export function getPromotionModal() {
    return (dispatch) => {
        dispatch({
            type: "GET_ACTIVE_PROMOTION_MODAL"
        })
    }
}