import React from 'react'
import styled from "styled-components"

import PropTypes from 'prop-types'

import { device } from '../device'

import { connect } from 'react-redux'

import { UseApi } from "../API";

const Container = styled.div`
-webkit-appearance: none;
display: flex;
height: 100%;
& div {
    // border: 1px solid red
}
padding: 0 100px;

@media ${device.mobileL} {
    max-width: 425px;
    padding: 0 50px;
    display: block;
    flex-direction: row;
}
`

const ContactForm = styled.div`
width: 70%;
padding-top: 50px;
padding-right: 50px;
& p.header {
    font-family: "Bebas Neue";
    font-size: 49px;
    font-weight: 900;
    margin: 0;
    padding: 0;
}

& p.subheader {
    font-family: Poppins;
    font-size: 16px;
}

& #formContact {
    font-family: Poppins;
    font-size: 16px;
}

& .formInput {
    padding-top: 20px;
    width: 80%;
}

& input[type=text] {
    width: 100%;
    padding: 12px 30px;
    font-size: 16px;
    font-family: Poppins;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

& textarea {
    width: 100%;
    padding: 12px 30px;
    font-size: 16px;
    font-family: Poppins;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 200px;
}

& input[type=submit] {
    background: black;
    color: white;
    width: 90px;
    height: 50px;
    float: right;
    font-size: 16px;
    font-family: Poppins;
    border: none;
    -webkit-appearance: none;
}

@media ${device.mobileL} {
    width: 100%;
    margin-top: 20px;

    & .formInput {
        width: 100%;
        margin-bottom: 100px;
    }
}
`

const LocationList = styled.div`
width: 30%;
float: rigth;
font-family: Poppins;
font-size: 16px;
& .header {
    font-size: 21px;
}

@media ${device.mobileL} {
    width: 100%;
    float: inherit;
}
`

const Location = styled.div`
margin-bottom: 30px;

& p {
    margin: 5px 0;
    padding: 0;
}
& .locationTitle {
    font-weight: bolder;
}
`

const FeedbackBad = styled.p`
    font-size: 16px;
    color: red;
    margin-top: unset;
    & p {
        margin-top: unset;
    }
`
const FeedbackGood = styled.p`
    font-size: 16px;
    color: green;
    margin-top: unset;
    & p {
        margin-top: unset;
    }
`

function ContactUs(props) {
    const [submitError, setSubmitError] = React.useState(undefined);
    const [submitSuccessful, setSubmitSuccessful] = React.useState(false);

    const submitMessage = (email, subject, message) => {
        return UseApi(
            "post",
            `${process.env.REACT_APP_BASE_URL}/message/create-message`,
            {email, subject, message}
            ).then((data) => {
                if(data.code !== 200){
                    setSubmitError('oops! something wrong in the server!')
                    setSubmitSuccessful(false)
                }else{
                    document.getElementById('formContact').reset()
                    setSubmitError(undefined)
                    setSubmitSuccessful(true)
                }
            });
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    let {language} = props

    let content = {
        English: {
            header: 'Get In Touch',
            sub_header: 'Please fill up all the required form and we will be in touch with lightning speed from email',
            email: 'Email',
            subject: 'Subject',
            message: 'Message',
            location_header: 'Connect With Us',
            send: 'Send'
        },
        Indonesia: {
            header: 'Hubungi Kami',
            sub_header: 'Silahkan mengisi form yang telah disediakan dan kami akan segera menghubungi anda melalui email yang tercantum.',
            email: 'Email',
            subject: 'Subjek',
            message: 'Pesan Anda',
            location_header: 'Terhubung dengan Kami',
            send: 'Kirim'
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const email = document.getElementById('email').value
        const subject = document.getElementById('subject').value
        const message = document.getElementById('message').value
        const isValidEmail = String(email).toLowerCase().match(emailRegex)
        if(!isValidEmail){
            setSubmitError('Email is not valid!')
            setSubmitSuccessful(false)
        }else if(!subject || !message){
            setSubmitError('subject and message input are required')
            setSubmitSuccessful(false)
        }else{
            submitMessage(email, subject, message)
        }
    }

    return (
        <>
            <Container>
                <ContactForm>
                    <p className="header">{content[language].header}</p>
                    <p className="subheader">{content[language].sub_header}</p>
                    <div className="formInput">
                        <form action="" id="formContact">
                            <label>{content[language].email}</label>
                            <input id='email' type="text" name="Email" placeholder="Your Email.."/>

                            <label>{content[language].subject}</label>
                            <input  id='subject' type="text" name="Subject" placeholder="Subject.."/>
                        
                            <label>{content[language].message}</label>
                            <textarea id='message' type="text" name="Message" placeholder="Your Message.."/>

                            <input type="submit" onClick={handleSubmit} value={content[language].send}/>
                        </form>
                        <FeedbackBad>{!!submitError && submitError}</FeedbackBad>
                        <FeedbackGood>{!!submitSuccessful && <p>Successfully Sent Message</p>}</FeedbackGood>
                    </div>
                </ContactForm>
                <LocationList>
                    <p className="header">{content[language].location_header} :</p>
                    <Location>
                        <p className="locationTitle">Start Tire Service Pecenongan</p>
                        <p>Pecenongan St No.25, Kebon Kelapa, Gambir, Central Jakarta City, Jakarta 10120.</p>
                        <p>Phone : (021) 3440680</p>
                    </Location>
                    <Location>
                        <p className="locationTitle">Start Tire Service Penjaringan</p>
                        <p>Jl. Muara Karang Raya No.243, Pluit, Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14450.</p>
                        <p>Phone : (021) 6697420</p>
                    </Location>
                    <Location>
                        <p className="locationTitle">Start Tire Service Serpong</p>
                        <p>Bumi Serpong Damai Ruko Blok L No. 21-22, Lengkong Wetan, Serpong Sub-District, South Tangerang City, Banten 15311.</p>
                        <p>Phone: (021) 5371012</p>
                    </Location>
                </LocationList>
            </Container>
        </>
    )
}

ContactUs.propTypes  = {
    language: PropTypes.string
}

const mapStatetoProps = state => {
    console.log('ini store state', state)
    return {
        language: state.language,
    }
}

export default connect(mapStatetoProps)(ContactUs)
