import React from "react";
import { UseApi } from "../API";

import {
  Container,
  Header,
  Body,
  ParagrafContainer,
  ImageContainer,
  Image1,
  Image2,
} from "./wheelAlignment.style";

import PropTypes from "prop-types";

import { connect } from "react-redux";

import WheelAlignmentImg1 from "../assets/home-aboutUs2.jpg";
import WheelAlignmentImg2 from "../assets/wheelbalancing.jpg";

function WheelAlignment(props) {
  const [content, setContent] = React.useState(undefined);

  const getContent = () => {
    return UseApi(
      "get",
      `${process.env.REACT_APP_BASE_URL}/service/get-detail-service/zvadwz71yk`,
      {}
    ).then((data) => {
      setContent(data.payload.payload);
    });
  };

  React.useEffect(() => {
    getContent();
  }, []);

  let { language } = props;

  return content ? (
    <>
      <Container>
        <Header>
          <p>{content[language].header}</p>
        </Header>
        <Body>
          <ParagrafContainer>
            <p className="firstParagraph">{content[language].first_pg}</p>
            <p>{content[language].second_pg}</p>
            <p>{content[language].third_pg}</p>
            <p>
              {content[language].fourth_pg}
              <ul>
                {content[language].list.map((val, index) => (
                  <li key={index}>{val}</li>
                ))}
              </ul>
            </p>
            <p>{content[language].fifth_pg}</p>
            <p>{content[language].sixth_pg}</p>
          </ParagrafContainer>
          <ImageContainer>
            <Image2 src={WheelAlignmentImg1} />
            <Image1 src={WheelAlignmentImg2} />
          </ImageContainer>
        </Body>
      </Container>
    </>
  ) : (
    <></>
  );
}

WheelAlignment.propTypes = {
  language: PropTypes.string,
};

const mapStatetoProps = (state) => {
  console.log("ini store state", state);
  return {
    language: state.language,
  };
};

export default connect(mapStatetoProps)(WheelAlignment);
