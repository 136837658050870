const defaultValue = {
    language : 'English',
    promotionModal_id: undefined
}

export default function reducer(state = defaultValue, action) {
    switch (action.type){
        case "SET_LANGUAGE": 
            return {
                ...state,
                language: action.data
            }
        case "GET_LANGUAGE":
            return {
                ...state,
                data: action.data
            }
        case "SET_ACITVE_PROMOTION_MODAL": 
            return {
                ...state,
                promotionData: action.data
            }
        case "RESET_ACTIVE_PROMOTION_MODAL":
            return {
                ...state,
                promotionData: undefined
            }
        case "GET_ACTIVE_PROMOTION_MODAL" :
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}