import React from 'react'
import styled from "styled-components"

import PropTypes from 'prop-types'
import { device } from '../device'
import { connect } from 'react-redux'

import { UseApi } from "../API";

import OfferImg1 from "../assets/home-aboutUs2.jpg"
import ReactHtmlParser from 'react-html-parser';

const Container = styled.div`
    background: rgba(0,0,0,0.4);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    opacity: 0;
    pointer-events: none;
    & div {
        // border: 0.5px solid red
    }
    overflow: auto;
    z-index: 1;
    transition: 0.5s;
    // animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    
    @media ${device.mobileL} {
        max-width: 425px;
    }

`

const Modal = styled.div`
    background: white;
    color: black;
    width: 50%;
    height: 80%;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    padding: 30px 30px;
    margin-bottom: 20%;

    @media ${device.mobileL} {
        width: 280px;
        height: auto;
        padding: 50px 10px;
        // top: 5%;
        // transform: translate(16.5%, -15%);
    }
`

const ModalHeader = styled.div`
height: 15%;
font-family: poppins;

& h1 {
    font-size: 21px;
    font-weight: 900;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 0;
}

& p {
    font-size: 12px;
    // margin: 0;
    padding: 0;
    font-weight: bold;
    color: #bdbdbd;
}
`

const Separator = styled.div`
height: 5%;
& hr {
    color: #E9E9E9;
}
`

const ModalBody = styled.div`
padding-top: 20px;
display: flex;
width: 100%;
height: 75%;

@media ${device.mobileL} {
    display: block;
    flex-display: row;
}
`

const ImageContainer = styled.div`
width: 50%;
padding-right: 20px;
text-align: center;
& img {
    object-fit: fit;
    max-width: 100%;
    max-height: 100%;
}

@media ${device.mobileL} {
    width: 100%;

}
`

const OfferDescription = styled.div`
position: relative;
width: 50%;
font-size: 12px;
font-family: Poppins;
height: auto;

& ol {
    padding-left: 1em;
}

& input[type=text] {
    width: 100%;
    padding: 12px 30px;
    font-size: 12px;
    font-family: Poppins;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.description-container {
    overflow-y: scroll;
    height: 60%;
    scrollbar-width: thin;
}

@media ${device.mobileL} {
    font-size: 10px;
    width: 100%;
}
`

const ButtonOffer = styled.button`
width: 100%;
color: white;
background: black;
padding: 10px;
bottom: 0;
font-weight: 900;
letter-spacing: 0.5px;
border: none;

@media ${device.mobileL} {
    position: relative;
}
`

const CloseButton = styled.a`
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 25px;
    text-decoration: none;
    color: black;

    &:hover {
        color: red;
    }
`

const DescriptionHeader = styled.p`
    font-weight: 700;
    margin-bottom: unset;
`

const FeedbackBad = styled.p`
    font-size: 10px;
    color: red;
    margin-top: unset;
    & p {
        margin-top: unset;
    }
`
const FeedbackGood = styled.p`
    font-size: 10px;
    color: green;
    margin-top: unset;
    & p {
        margin-top: unset;
    }
`

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function specialOfferDetail(props) {
    const [submitError, setSubmitError] = React.useState(undefined);
    const [submitSuccessful, setSubmitSuccessful] = React.useState(false);

    const submitEmail = (email) => {
        return UseApi(
            "post",
            `${process.env.REACT_APP_BASE_URL}/promotion/subscribe-promotion`,
            {email, promotionId: promotionData.id}
            ).then((data) => {
                if(data.payload.alreadySubscribed){
                    setSubmitError('Email already used to submit promotion!')
                    setSubmitSuccessful(false)
                }else{
                    setSubmitError(undefined)
                    setSubmitSuccessful(true)
                }
            });
    }

    let { language, promotionData} = props

    const closeModal = (e) => {
        e.preventDefault()
        let modal = document.getElementById("modal-special-offer")
        modal.style.opacity = 0
        modal.style.pointerEvents = "none"
    }

    let content = {
        English: {
            header: 'Special Offers',
            sub_header: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            take_offer_btn: 'Take Offer',
            detail: 'View Offer Details'
        },
        Indonesia: {
            header: 'Penawaran Menarik',
            sub_header: 'Indonesia Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            take_offer_btn: 'Ambil Penawaran',
            detail: 'Lihat Detail Penawaran'
        }
    }

    const validateUserPromo = () => {
        const email = document.getElementById('userEmail').value
        const isValidEmail = String(email).toLowerCase().match(emailRegex)
        if(!isValidEmail){
            setSubmitError('Email is not valid!')
            setSubmitSuccessful(false)
        }else{
            submitEmail(email)
        }
        
    }

    return (
        <>
        <Container id="modal-special-offer">
            {promotionData && <Modal>
                <CloseButton href="" onClick={closeModal}>X</CloseButton>
                <ModalHeader>
                    <h1>{promotionData.title}</h1>
                    <p>{promotionData.promotionType}</p>
                </ModalHeader>
                <Separator><hr></hr></Separator>
                <ModalBody>
                    <ImageContainer>
                        <img src={OfferImg1} />
                    </ImageContainer>
                    <OfferDescription>
                        <div className='description-container'>
                            <DescriptionHeader>Terms & Condition :</DescriptionHeader>
                            {/* <span>{promotionData.description}</span> */}
                            {ReactHtmlParser(promotionData.body)}
                        </div>
                        <div>
                        <DescriptionHeader>Input your email here to get promotion :</DescriptionHeader>
                        <input id="userEmail" type="text" name="Email" placeholder="Your Email.."
                        pattern='/^[^ ]+@[^ ]+\.[a-z]{2,3}$/'
                        />
                        <FeedbackBad>{!!submitError && submitError}</FeedbackBad>
                        <FeedbackGood>{!!submitSuccessful && <p>Successfully Take Promotion Offer</p>}</FeedbackGood>
                        <ButtonOffer onClick={validateUserPromo}>{content[language].take_offer_btn}</ButtonOffer>
                        </div>
                    </OfferDescription>
                </ModalBody>
            </Modal>}
        </Container>
        </>
    )
}

specialOfferDetail.propTypes  = {
    language: PropTypes.string,
    promotionData: PropTypes.object
}

const mapStatetoProps = state => {
    return {
        language: state.language,
        promotionData: state.promotionData
    }
}

export default connect(mapStatetoProps)(specialOfferDetail)
