import React from 'react'
import styled from "styled-components"

import newsImage from "../assets/home-aboutUs2.jpg"

const Container = styled.div`
display: flex;
height: 100%;
& div {
    // border: 1px solid red
}
padding: 0 100px;
`

const NewsContainer = styled.div`
width: 70%;

& .NewsTitle {
    font-size: 49px;
    widt: 820px;
    font-weight: 900;
    margin: 0;
}

& .date {
    color: #bdbdbd;
}

& p {
    font-size: 16px;
    font-family: Poppins;
    margin-top: 50px;
}

`

const NewsList = styled.div`
width: 30%;
float: rigth;
font-family: Poppins;
font-size: 16px;
& .header {
    font-size: 21px;
}
`

const NewsImage = styled.img`
    width: 820px;
    height: 500px;
    object-fit: cover;
    margin-top: 50px;
`

const NewsContent = styled.div`
    width: 820px;
`

const NewsItem = styled.div`

`

export default function news() {
    return (
        <>
            <Container>
                <NewsContainer>
                    <NewsContent>
                        <p className="date">Sunday, 1 Nov 2012</p>
                        <p className="NewsTitle">Lorem Ipsum is simply dummy text of the printing</p>
                        <NewsImage src={newsImage}/>
                        <p><b>Lorem Ipsum</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </NewsContent>
                </NewsContainer>
                <NewsList>
                    <p className="header">Another News :</p>
                    <NewsItem>
                        <p className="locationTitle">Start Tire Service Pecenongan</p>
                        <p>Pecenongan St No.25, Kebon Kelapa, Gambir, Central Jakarta City, Jakarta 10120.</p>
                        <p>Phone : (021) 3440680</p>
                    </NewsItem>
                    <NewsItem>
                        <p className="locationTitle">Start Tire Service Penjaringan</p>
                        <p>Jl. Muara Karang Raya No.243, Pluit, Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14450.</p>
                        <p>Phone : (021) 6697420</p>
                    </NewsItem>
                    <NewsItem>
                        <p className="locationTitle">Start Tire Service Serpong</p>
                        <p>Bumi Serpong Damai Ruko Blok L No. 21-22, Lengkong Wetan, Serpong Sub-District, South Tangerang City, Banten 15311.</p>
                        <p>Phone: (021) 5371012</p>
                    </NewsItem>
                </NewsList>
            </Container>
        </>
    )
}
