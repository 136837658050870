import React from "react"
import styled from "styled-components"

import PropTypes from 'prop-types'

import { device } from '../device'

import { connect } from 'react-redux'

import ModalSpecialOffer from "../components/specialOfferDetail"
import { setPromotionModal } from '../store/action'

import { UseApi } from "../API";

import ReactHtmlParser from 'react-html-parser';

const Container = styled.section`
// background: papayawhip;
// width: 100%;
padding:0 100px;

// & div {
//     border: 1px solid blue;
// }

& a {
    text-decoration: none;
}

@media ${device.mobileL} {
    max-width: 425px;
    padding: 0 50px;
}
`

const Header = styled.div`
height: 10%;
width: 100%;
margin-bottom: 80px;
& h1 {
    font-size: 49px;
    font-family: 'Bebas Neue';
    font-weight: bold;
    align-items: baseline;
    margin-top: 80px;
    margin-bottom: 0;
}
& p {
    font-size: 16px;
    font-family: 'Poppins';
}

& p.placeholder {
    text-align: center;
    font-weight: bold;
    font-size: 36px;
}

@media ${device.mobileL} {
    margin-bottom: 30px;

    & h1 {
        font-size: 28px;
        margin-top: 30px;
    }
}
`

const Body = styled.div`
display: -webkit-box;
height: auto;
width: 100%;
border-bottom: 2px solid #E9E9E9;
padding-bottom: 100px;
overflow-x: scroll;
scrollbar-width: none;
&::-webkit-scrollbar {
    display: none;
}

@media ${device.mobileL} {
    // display: block;
    flex-direction: row;
    padding-bottom: 30px;
    font-size: 12px;
}
`

const OffersContainer = styled.div`
height: 400px;
width: 35%;
padding: 10px;
border: 2px solid #E9E9E9;
margin: 0 10px;
position: relative;

@media ${device.mobileL} {
    width: 75%;
    margin: 20px 10px;
    height: 250px;
}
`

const OffersHeader = styled.div`
height: 10%;

& * {
    margin: 0;
    padding: 0;

}

& h4 {
    font-family: 'Bebas Neue'
}

& p {
    font-family: 'Poppins'
}

@media ${device.mobileL} {
    height: auto;
}

@media ${device.laptop} {
    height: auto;
}
`

const OffersBody = styled.div`
overflow-y: scroll;
height: 70%;
margin: 20px 0;
& h4 {
    font-family: 'Bebas Neue'
}

& p {
    font-family: 'Poppins'
}

& a {
    font-family: 'Poppins';
    color: #B52025;
    font-weight: bold;
}

@media ${device.mobileL} {
    height: 50%;
}

@media ${device.laptop} {
    // height: auto;
}

`

const ButtonContainer = styled.div`
    height: 10%;
`

const OffersButton = styled.button`
width: 80%;
height: 40px;
background-color: black;
color: white;
font-size: 16px;
font-family: 'Poppins';
font-weight: bold;
// margin-top: 10px;
border: none;
bottom: 1rem;
position: absolute;
left: 50%;
transform: translateX(-50%);

@media ${device.mobileL} {
    height: auto;
    font-size: 12px;
}

@media ${device.laptop} {
    height: auto;
}
`

function SpecialOffers(props) {

    let { language, setPromotionModal } = props

    const [promotions, setPromotions] = React.useState(undefined);

    const getPromotions = () => {
        return UseApi(
        "get",
        `${process.env.REACT_APP_BASE_URL}/promotion/get-all-promotions`,
        {}
        ).then((data) => {
        setPromotions(data.payload.rows);
        });
    };

    React.useEffect(() => {
        getPromotions();
    }, []);

    let content = {
        English: {
            header: 'Special Offers',
            sub_header: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            empty_placeholder: 'Coming Soon!',
            take_offer_btn: 'Take Offer',
            detail: 'View Offer Details'
        },
        Indonesia: {
            header: 'Penawaran Menarik',
            sub_header: 'Indonesia Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            empty_placeholder: 'Akan Datang!',
            take_offer_btn: 'Ambil Penawaran',
            detail: 'Lihat Detail Penawaran'
        }
    }

    const openModal = (e) => {
        e.preventDefault()
        let modal = document.getElementById("modal-special-offer")
        modal.style.opacity = 1
        modal.style.pointerEvents = "auto"
    }

    return (
        <>
            <ModalSpecialOffer/>
            <Container>
                <Header>
                    <h1>{content[language].header}</h1>
                    {/* <p>{content[language].sub_header}</p> */}
                </Header>
                <Body>
                    {(!promotions || promotions.length === 0) && <Header><p className="placeholder">{content[language].empty_placeholder}</p></Header>}
                    {!!promotions && promotions.map((val, index) => (
                        <OffersContainer key={index}>
                            <OffersHeader>
                                <h4>{val.title}</h4>
                                <p>{val.promotionType}</p>
                            </OffersHeader>
                            <OffersBody>
                                <h4>Offer Description:</h4>
                                {/* <p>{val.description}</p> */}
                                {ReactHtmlParser(val.body)}
                                {/* <a href="#">{content[language].detail}</a> */}
                            </OffersBody>
                            <ButtonContainer>
                                <OffersButton onClick={(e) => {openModal(e); setPromotionModal(val);}}>{content[language].detail}</OffersButton>
                            </ButtonContainer>
                        </OffersContainer>
                    ))}
                    
                </Body>
            </Container>
        </>
    )
}

SpecialOffers.propTypes  = {
    language: PropTypes.string,
    setPromotionModal: PropTypes.func
}

const mapStatetoProps = state => {
    return {
        language: state.language
    }
}

const mapDispatchtoProps = {
    setPromotionModal
}

export default connect(mapStatetoProps, mapDispatchtoProps)(SpecialOffers)